//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//


// Theme colors
$primary:                     #7e736e !default;
$secondary:                   #eae6e5 !default;

// Fonts
$font-family-sans-serif:                    "Titillium Web", sans-serif !default;
$line-height-base:                          1.5 !default;

// Buttons
$btn-border-radius: 8px;
$input-btn-padding-y:                       .8rem !default;
$input-btn-padding-x:                       1.7rem !default;
$btn-font-size:                       1.2 !default;
